import * as React from "react"
import { graphql } from 'gatsby'
import "../styles/main.css"
import Header from "../components/header.js"
import ProjectsGallery from "../components/projectsGallery.js"
import Hero from "../components/hero.js"
import Helpers from "../components/helpers.js"
import Footer from "../components/footer.js"
import CtaBanner from "../components/ctaBanner.js"

const ProjectsPage = (props) => {


  const intro = props.props.intro;
  const helpers = props.props.helpers;
  const bigBanner = props.props.bigBanner;

  return (
    <main>
      <Header page={2}></Header>
      <Hero title={intro.title} text={intro.text} image={intro.image} orientation="horizontal"></Hero>
      <ProjectsGallery></ProjectsGallery>
      {/* <Helpers helpers={helpers}></Helpers> */}
      <CtaBanner bigBanner={bigBanner}></CtaBanner>
      <Footer></Footer>
    </main>
  )
}

const ProjectsPageExport = ({ data }) => {
  const params = data.allMdx.nodes[0].frontmatter
  return (
    <ProjectsPage props={params}></ProjectsPage>
  )
}

export default ProjectsPageExport

export const pageQuery = graphql`
query ProjectsPageQuery {
    allMdx(filter: {frontmatter: {templateKey: {eq: "projects"}}}) {
      nodes {
        frontmatter {
          intro {
            image
            text
            title
          }
          bigBanner {
            buttonText
            title
          }
          helpers {
            name
          }
        }
      }
    }
  }
  

`
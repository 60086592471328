import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import useWindowDimensions from "./useWindowDimensions"
import cn from "classnames"
import "../styles/projectsGallery.css"

function ProjectsGallery(props) {

    let [page, setPage] = useState(0);
    let { height, width } = useWindowDimensions()
    return (
        <StaticQuery
            query={graphql`
            query ProjectsGalleryQuery {
                allMdx(
                  sort: {fields: [frontmatter___date], order: DESC}
                  limit: 1000
                  filter: {frontmatter: {templateKey: {eq: "project"}}}
                ) {
                  nodes {
                    frontmatter {
                      title
                      text
                      before{image}
                      after{image}
                    }
                  }
                }
              }
              
      `}
            render={data => {
                let gallery = data.allMdx.nodes;
                gallery = splitArray(gallery)
                console.log(gallery)

                return (
                    <div className="projectsGallery">
                        <div className="projectsGalleryIn">
                            <span className="projectsGaleryArrowLeft" onClick={() => page > 0 ? setPage(page - 1) : ""}></span>
                            {renderPages(gallery)}
                            <span className="projectsGaleryArrowRight" onClick={() => page < gallery.length - 1 ? setPage(page + 1) : ""}></span>
                        </div>
                    </div>
                )
            }}
        />

    )

    function renderPages(pages) {
        return (
            <>
                {
                    pages.map((item, i) => {
                        // console.log("page, i ", page, " ", i, " ", page !== i)
                        return (
                            <div key={i} className={cn("projectGalleryList", { "dn": page !== i })}>{renderGallery(item)}</div>
                        )
                    })
                }
            </>
        )

    }

    function renderGallery(gallery) {
        return gallery.map((item, i) => {
            let name;
            if (!item.frontmatter) name = ""
            else name = item.frontmatter.title.toLowerCase().split(" ").join("-");
            if (item.empty) {
                if (width > 785) {
                    return <div href={"/project/" + name} key={i} className={i % 2 === 0 ? "projectsGalleryItem mr10 projectsGalleryItemEmpty" : "projectsGalleryItem projectsGalleryItemEmpty"}>
                        <div></div>
                    </div>
                }
                else return null
            }
            return (
                <a href={"/project/" + name} key={i} className={i % 2 === 0 ? "projectsGalleryItem mr10" : "projectsGalleryItem"}>
                    <img className="projectsGalleryImg" src={item.frontmatter.after[0].image} alt="" />
                    <div className="projectsGalleryHover"></div>
                    <div className="projectsGalleryTitle">{item.frontmatter.title}</div>
                </a>
            )
        })
    }

    function splitArray(array) {
        //let perChunk = 4 // items per chunk
        let perChunk = width > 1200 ? 4 : 2;

        let inputArray = [...array]

        let result = inputArray.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])

        if (result[result.length - 1].length < perChunk) {
            let diff = perChunk - result[result.length - 1].length;
            console.log(diff)
            for (let i = 0; i < diff; i++) {
                result[result.length - 1].push({ empty: true })
            }
        }

        return result
    }
}

export default ProjectsGallery